import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import './contact.scss';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { IconWrapper } from '../components/iconWrapper';
import { ContactPageQuery } from '../../graphql-types';

interface IProps {
  data: ContactPageQuery;
}
const Contact: React.FC<IProps> = ({ data, ...rest }) => {
  const contact = data.prismic.allContacts.edges[0].node;

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const disabled = name === '' || email === '' || message === '';

  const success = React.useMemo(() => (rest as any).location.search === '?success=true', []);
  return (
    <Layout
      className="contact"
      pageMeta={{
        title: 'Contact | Evie Harbury Food Stylist',
        description: contact.description[0].text,
        keywords: 'Contact, Chef, Food Stylist, Home Economist',
        image: {
          src: contact.image.url,
          height: contact.image.dimensions.height,
          width: contact.image.dimensions.width,
        },
      }}
    >
      <style>
        {`:root {
            --contact-focus-color: ${contact.image_border_colour};
        }`}
      </style>
      <div className="contact-container">
        <div className="contact-image-container">
          <Img
            fadeIn={true}
            backgroundColor="#F6F6F6"
            className="contact-img"
            style={{ borderColor: contact.image_border_colour }}
            fluid={contact.imageSharp.childImageSharp.fluid}
          />
        </div>

        <div className="contact-form-container">
          {success ? (
            <>
              <IconWrapper random="icon2">
                <h1>Thankyou!</h1>
              </IconWrapper>
              <p className="contact-text">{contact.success_message[0].text}</p>
              <AniLink to="/projects" cover direction="down" bg={`#656A71`}>
                Go to projects
              </AniLink>
            </>
          ) : (
            <>
              <IconWrapper random="icon2">
                <h1>{contact.title[0].text}</h1>
              </IconWrapper>
              <p className="contact-text">{contact.description[0].text}</p>
              <form
                name="contact"
                method="POST"
                action="/contact?success=true"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="hidden">
                  <label>
                    Don’t fill this out if you're human: <input name="bot-field" />
                  </label>
                </div>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Your name..."
                  onChange={(e) => setName(e.target.value)}
                />
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Your email..."
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label>Message</label>
                <textarea
                  name="message"
                  value={message}
                  placeholder="Your message..."
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                <button disabled={disabled} type="submit">
                  Send message
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactPage {
    prismic {
      allContacts {
        edges {
          node {
            title
            description
            image
            imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            image_border_colour
            success_message
          }
        }
      }
    }
  }
`;

export default Contact;
